import React, { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import homeBanner1 from "../../image/home_banner/admissionbanner1.webp";
import homeBanner2 from "../../image/home_banner/admissionbanner2.webp";
import homeBanner3 from "../../image/home_banner/admissionbanner3.webp";
import { Link } from "react-router-dom";
import "./Landing.css";

function Landing({ video, cover }) {
  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const openModal = (e) => {
    console.log("BUtton is clicked");
    e.stopPropagation();
    setModal(!modal);
  };

  const spinner = () => {
    setVideoLoading(!videoLoading);
  };
  const [isMuted, setIsMuted] = useState(true);

  return video ? (
    <div>
    <div className='player-wrappers'>
      {/* <ReactPlayer
        url={campus}
        loop={true}
        muted={isMuted}
        playing={true}  
        controls={false}
        width='100%'
        height='100%'
        className='react-player'
      /> */}
    <div id="carouselExampleIndicators" className="carousel slide ">
        <div>
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
            <Link to="/admissions">
              <img
                src={homeBanner1}
                width="100%"
              />
              
              </Link>
            </div>
            <div className="carousel-item">
            <Link to="/admissions">
              <img
                src={homeBanner2}
                width="100%"
              />
              </Link>
            </div>
            <div className="carousel-item">
            <Link to="/admissions">
              <img
                src={homeBanner3}
                width="100%"
              />
              </Link>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>{" "}
      </div>
    <center>
    <button onClick={openModal} className="button-modal">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-camera-reels-fill"
        viewBox="0 0 16 16"
      >
        <path d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
        <path d="M9 6a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
        <path d="M9 6h.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 7.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 16H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2z" />
      </svg>
      <br />
      Virtual Tour
      {modal ? (
        <section className="modal__bg">
          <div className="modal__align">
            <div className="modal__content" modal={modal}>
              <IoCloseOutline
                className="modal__close"
                arial-label="Close modal"
                onClick={setModal}
              />
              <div className="modal__video-align">
                {videoLoading ? (
                  <div className="modal__spinner">
                    <BiLoaderAlt
                      className="modal__spinner-style"
                      fadeIn="none"
                    />
                  </div>
                ) : null}
                <iframe
                  className="modal__video-style"
                  onLoad={spinner}
                  loading="lazy"
                  width="800"
                  height="500"
                  // src="https://youtu.be/zC62iEIO0OI?si=LOFUqHDQ9mw5MGR5"
                  src="https://www.youtube.com/embed/eV9ajlBfOAU?si=5PpsRzCBbou09HFq"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </button>
    </center>
    </div>
  ) : (
    <div className="cover_img_cont">
      <img src={cover} alt="images" className="cover_img" />
    </div>
  );
}

export default Landing;
